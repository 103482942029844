// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
	production: false,
	isMockEnabled: true, // You have to switch this, when your real back-end is done
	authTokenKey: "authce9d77b308c149d5992a80073637e4d5",
	frontUrl: "https://console.connecttherelevant.com", //local
	frontendUrl: "https://console.connecttherelevant.com", // local
	baseURL: "https://connecttherelevant.com:3301/api/v1/", // local https://localhost:3301/
	imagebucketurl: "https://connecttherelevantstaging.s3.ap-south-1.amazonaws.com",
	videobucketurl: "https://connecttherelevantstaging.s3.ap-south-1.amazonaws.com",
	accesskeyId: "AKIAIGR2SBDQJRDDFELA",
	countrydropdown: true,
	languagedropdown: true,
	wss: "wss://connecttherelevant.com:3301/",
	socketUrl: "https://connecttherelevant.com:3302/",
};
