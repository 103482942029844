import { Component, Input, HostListener, ElementRef, OnInit, OnDestroy } from "@angular/core";

@Component({
	selector: "tooltip-v2",
	template: `
		<div class="tooltip-container" *ngIf="showTooltip">
			<ng-content></ng-content>
			<span class="tooltip-text">{{ text }}</span>
		</div>
	`,
	styleUrls: ["./tooltip.component.scss"],
})
export class TooltipComponentV2 implements OnInit, OnDestroy {
	@Input() text = ""; // Tooltip text
	@Input() showTooltip: boolean = true;

	constructor(private el: ElementRef) {}
	ngOnDestroy(): void {}
	ngOnInit(): void {}
}
