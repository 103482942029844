import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef, EventEmitter, Inject } from "@angular/core";
import { MatPaginator, MatSort, MatTableDataSource, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ToastrService } from "ngx-toastr";

interface singleCategory {
	_id: string;
	title: string;
	description: string;
	popular: boolean;
	status: number;
	parent: string | null;
	parent_title: string | null;
	order: number;
	subcategory: singleCategory[];
	selected: boolean | false;
	// Add other properties as needed
}

export interface DialogData2 {
	artistForTeam: Array<singleCategory>;
	CategoryTitle: string;
	categoryArr: any;
	// customFunction: any;
}
@Component({
	selector: "tfilter-seached-artiste",
	templateUrl: "./showInSearchMembers.html",
	styleUrls: ["./showInSearchMembers.scss"],
})
export class TFilterSeachedArtiste {
	private subscription;
	emailError: any = "";
	otpDisabled = false;
	selectedCatArr = [];
	selectedProdArr = [];
	memberData = [];
	filteredMembers: any[] = [];
	searchText: string = "";
	inputValue = "";
	newCategoryArr: Array<singleCategory>;
	private addManager: EventEmitter<boolean> = new EventEmitter();
	SaveButtonToggle = false;
	constructor(public dialogRef: MatDialogRef<TFilterSeachedArtiste>, private toastr: ToastrService, private cdr: ChangeDetectorRef, @Inject(MAT_DIALOG_DATA) public data: DialogData2) {}
	ngOnInit() {
		// this.selectedCatArr = [...this.data.profession];
		// this.formateCategoryWithParent();

		this.memberData = this.data.artistForTeam;
		this.filteredMembers = this.memberData;
	}

	applyFilter(): void {
		const lowerSearchText = this.searchText.toLowerCase();
		let matchProfession = "";
		this.filteredMembers = this.memberData.filter((member) => {
			const titles = this.data.categoryArr.filter((category) => member.professions.includes(category._id)).map((category) => category.title);
			const matchProfession = titles.some((title) => title.toLowerCase().includes(lowerSearchText));
			const matchName = member.name.toLowerCase().includes(lowerSearchText);
			return matchName || matchProfession;
		});
	}

	save(): void {}

	// formateCategoryWithParent(inputValue = "") {
	//     const getParent = new Set(this.data.category.map((e) => e.parent_title));
	//     let categoryData = [];
	//     getParent.forEach((e) => {
	//         if (e) {
	//             categoryData.push({
	//                 title: e,
	//                 data: this.data.category.filter((c) => c.parent_title == e && !this.selectedCatArr.map((s) => s._id).includes(c._id) && c.title && c.title.toLowerCase().includes(inputValue)),
	//             });
	//         }
	//     });
	//     categoryData = categoryData.filter((e) => e.data.length != 0);
	//     categoryData.sort((a, b) => {
	//         if (a.title.toLowerCase() === "main") return -1;
	//         return a.title.localeCompare(b.title);
	//     });
	//     categoryData = categoryData.map((e) => ({ ...e, title: e.title.toLowerCase() == "main" ? "" : e.title }));

	//     this.newCategoryArr = categoryData;
	//     this.cdr.detectChanges();
	// }

	selectMember(member) {
		this.SaveButtonToggle = true;
		this.selectedProdArr.push(member);
		this.filteredMembers = this.filteredMembers.filter((keyword) => keyword._id !== member._id);
		// this.socialLinkOrder = this.socialLinkOrder.filter((keyword) => keyword.type == "social" && keyword.label !== pill.label);
	}
	removeMember(member) {
		this.selectedProdArr = this.selectedProdArr.filter((keyword) => keyword._id !== member._id);
		this.filteredMembers.unshift(member);
	}
	handleCategoryClick(category: singleCategory) {
		this.SaveButtonToggle = true;
		this.selectedCatArr.push(category);
		// this.formateCategoryWithParent(this.inputValue.toLowerCase());
	}
	onInputChange(event: Event) {
		// You can access the input value using event.target.value
		this.inputValue = (event.target as HTMLInputElement).value;
		// this.formateCategoryWithParent(this.inputValue.toLowerCase());
		this.cdr.detectChanges();
	}
	onKeyDown(event: KeyboardEvent, value: string) {
		if (event.key === "Backspace" && value.length <= 1) {
			// this.formateCategoryWithParent();
			// let value = document.getElementById("catageorySearchButton").value;
		}
	}

	submitCategory() {
		this.dialogRef.close(this.selectedProdArr);
	}

	resetCategory() {
		this.selectedProdArr = [];
		this.filteredMembers = this.data.artistForTeam
	}
	closeDialoge() {
		this.dialogRef.close();
	}

	removePill(pill): void {
		this.SaveButtonToggle = true;
		this.selectedProdArr = this.selectedProdArr.filter((keyword) => keyword._id !== pill._id);
		// this.formateCategoryWithParent();
	}

	// ngOnDestroy(): void {
	// 	this.newCategoryArr = [];
	// 	this.dialogRef.close(this.selectedCatArr);
	// }
}
