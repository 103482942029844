import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class UserProfileService {
	private _updateComponentSource = new Subject<any>();

	updateComponent$ = this._updateComponentSource.asObservable();

	triggerUpdate(val) {
		this._updateComponentSource.next(val);
	}
}
