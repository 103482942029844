// USA

const projectname = "CONNECT THE RELEVANT";

export const locale = {
	lang: "en",
	data: {
		UPDATE: "Update",
		LIST: "List",
		HOME: "Home",
		ADD: "Add",
		NAME: "Name",
		ADSH: "Adsh",
		CIK: "CIK",
		SIC: "SIC",
		COUNTRY: "Country",
		STPRBA: "State",
		CITY: "City",
		ZIP: "ZIP",
		BAS1: "BAS 1",
		BAS2: "BAS 2",
		COUNTRY_ID: "id",
		Usergroup: "User Group",
		QUESTION_TYPE: "question type",
		ANSWER_INPUT: "answer input",
		ANSWER_TYPE: "answer type",
		QUESTION_TEXT: "question text",
		DESCRIPTION: "description",
		FIRSTNAME: "First name",
		LASTNAME: "Last name",
		EMAIL: "Email",
		PHONE: "Phone",
		COUNTRY_CODE: "Country code",
		REGIONS: "Regions",
		STATES: "State",
		CITIES: "City",
		LOCALE: "Locale",
		COUNTRIES: "Countries",
		CATEGORY: "Category",
		VALUES: "Values",
		SETTINGTYPES: "SETTING TYPES",
		ANSWER_OPTIONS: "ANSWER OPTIONS",
		TRANSLATE: "Translate",
		TITLE: "Title",
		CONTACT_NO: "Contact no",
		USERGROUPS: "User group",
		USERS: "User",
		ZONES: "Zone",
		WARD: "Ward",
		CONVENIENCES: "Name of public convenience",
		TRANSLATOR: {
			SELECT: "Select your language",
		},
		MENU: {
			CHAT: "Chat Connections",
			SEARCH: "Search",
			LABEL: "Label",
			SOCIALLINK: "Social Link",
			BLOCKED: "Blocked",
			DELETE: "Delete Account",
			OCCUPATIONSUGGESTION: "Occupation Suggestion",
			EARLYACCESS: "Early Access",
			TEAM: "Team",
			MANAGERINVITE: "Official Representatives Invitation",
			NEW: "new",
			ACTIONS: "Actions",
			CREATE_POST: "Create New Post",
			PAGES: "Pages",
			WARD: "Ward",
			FEATURES: "Features",
			APPS: "Apps",
			DASHBOARD: "Dashboard",
			ASSIGNMENT: "Assignment List",
			USERS: "Team Members",
			MASTERS: "Masters",
			MODULES: "Modules",
			COMPANY: "Company",
			REGION: "Region",
			COUNTRY: "Country",
			STATE: "State",
			OCCUPATION: "User Occupations",
			CMSPAGE: "CMS Page",
			ARTISTE: "Talents",
			MANAGE: "Official Representatives",
			NOTIFICATION: "Notifications",
			COUPONCODE: "Promo codes",
			// COUPONCODE:"Coupon Codes",
			CUSTOMER: "Industry Professionals",
			PROFILEREQUEST: "Profile Update",
			PACKAGE: "Package",
			CITY: "City",
			DISTRICT: "District",
			ZONE: "Zone",
			TAG: "Tag",
			LANGUAGE: "Language",
			USERMANAGEMENT: "User Management",
			// USERGROUP: 'Team Roles',
			USERGROUP: "Roles",
			SEC: "SEC",
			GLOBALSUCCESS: "Global Success",
			PDF: "PDF",
			FIELDGROUPMAPPING: "Field Group Mapping",
			STATEMENTTYPENAME: "Statement Type",
			FIELDMAPPING: "Field Mapping",
			QUESTIONNAIRETOOL: "Questionnaire Tool",
			GLOBALSETTING: "Global Setting",
			SETTING: "Setting",
			SETTINGTYPE: "Setting Type",
			ACCESSLOGS: "Access Logs",
			SURVEYMANAGEMENT: "Survey Management",
			PUBLICCONVENIENCE: "Public Convenience",
			EMAILTEMPLATE: "Email Template",
			QUESTIONS: "questions",
			CATEGORY: "Talent Professions",
			MENU: "Menu",
			FEEDBACK: "App: Need Help",
			PROFILE: "Profile",
			MANAGER: "Manager",
		},
		AUTH: {
			GENERAL: {
				OR: "Or",
				SUBMIT_BUTTON: "Submit",
				NO_ACCOUNT: "Don't have an account?",
				SIGNUP_BUTTON: "Sign Up",
				FORGOT_BUTTON: "Forgot Password?",
				BACK_BUTTON: "Back",
				PRIVACY: "Privacy",
				LEGAL: "Legal",
				CONTACT: "Contact",
			},
			LOGIN: {
				TITLE: "Welcome Back!",
				ARTISTETITLE: "Talent Login",
				MANAGERTITLE: "Manager Login",
				WEBTITLE: "Welcome to Talent Login.",
				BUTTON: "SIGN IN",
				SUCCESSMESSAGE: "Successfully Login",
				OTPBUTTON: "Send OTP",
			},
			FORGOT: {
				TITLE: "Forgot Password",
				DESC: "Enter your email to reset your password",
				SUCCESS: "Link has been sent to your registered email using which you can reset your password.",
			},
			SETPASSWORD: {
				TITLE: "Set Password",
				BUTTON: "Reset",
				SUCCESS: "Password has been changed successfully",
			},
			REGISTER: {
				TITLE: "Sign Up",
				DESC: "Enter your details to create your account",
				SUCCESS: "Your account has been successfuly registered.",
			},
			INPUT: {
				EMAIL: "Email",
				FULLNAME: "Fullname",
				PHONE: "Phone",
				PASSWORD: "Password",
				CONFIRM_PASSWORD: "Confirm Password",
				USERNAME: "Username",
				TITLE: "Enter Title",
				REMEMBER_ME: "Remember Me",
				OTP: "OTP",
			},
			VALIDATION: {
				INVALID: "{{name}} is not valid",
				REQUIRED: "{{name}} is required",
				MIN_LENGTH: "{{name}} minimum length is {{min}}",
				AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
				NOT_FOUND: "The requested {{name}} is not found",
				INVALID_LOGIN: "The login detail is incorrect",
				REQUIRED_FIELD: "Required field",
				MIN_LENGTH_FIELD: "Minimum field length:",
				MAX_LENGTH_FIELD: "Maximum field length:",
				INVALID_FIELD: "Password incorrect",
				INVALID_EMAIL_ID:"User not found",
				MUST_MATCH: "Password and Confirm Password Must Match",
				OPTINVALID: "OTP code is not valid",
			},
		},
		PAGES: {
			MODULES: {
				LISTINGTITLE: projectname + " | Module List",
				ADDTITLE: projectname + " | Module Add",
				EDITTITLE: projectname + " | Module Update",
				MODULESLIST: "Modules List",
				MODULENAMEPLURAL: "modules",
				MODULENAMESINGULAR: "module",
				NEWMODULE: "New Module",
				DELETEDSELECTED: "Delete selected Modules",
				UPDATESELECTED: "Update status for selected Modules",
				ADDNEW: "Add New Module",
				UPDATE: "Update Module",
				CREATEMESSAGE: "Create Module Successfully",
				UPDATEMESSAGE: "Update Module Successfully",
				EDIT: "Edit Module",
				ADD: "Add Module",
				METHOD: "Method",
				AUTHCHECK: "Auth Check",
				ACLCHECK: "ACL Check",
				FUNCTION: "Function",
				NAME: "Name",
				ROUTEAPI: "API Route",
				ROUTEADMIN: "Admin Route",
				ROUTEFRONTEND: "Frontend Route",
				RIGHTS: "Right",
			},
			REGION: {
				LISTINGTITLE: projectname + " | Region List",
				ADDTITLE: projectname + " | Region Add",
				EDITTITLE: projectname + " | Region Update",
				REGIONLIST: "Regions List",
				REGIONNAMEPLURAL: "regions",
				REGIONNAMESINGULAR: "region",
				NEWREGION: "New Region",
				DELETEDSELECTED: "Delete selected Regions",
				UPDATESELECTED: "Update status for selected Regions",
				ADDNEW: "Add New Region",
				UPDATE: "Update Region",
				CREATEMESSAGE: "Create Region Successfully",
				UPDATEMESSAGE: "Update Region Successfully",
				EDIT: "Edit Region",
				ADD: "Add Region",
				REGION_NAME: "Region",
				REGION_ERROR: "Please Specify Region",
				PATTERN: {
					// tslint:disable-next-line: quotemark
					NAME: "(\\(?\\+?[^\\s0-9\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]+(\\s{0,1}[a-zA-Z-, ][^<>\\s])*\\)?)?[^0-9_\\-\\(\\)\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]",
				},
			},
			OCCUPATION: {
				LISTINGTITLE: projectname + " | User Occupation",
				ADDTITLE: projectname + " | Occupation Add",
				EDITTITLE: projectname + " | Occupation Update",
				LIST: "User Occupations",
				NAMEPLURAL: "occupations",
				POPULAR: "Popular",
				NAMESINGULAR: "occupation",
				NEW: "New Occupation",
				DELETEDSELECTED: "Delete selected Occupations",
				UPDATESELECTED: "Update status for selected Occupations",
				ADDNEW: "Add New Occupation",
				UPDATE: "Update Occupation",
				CREATEMESSAGE: "Create Occupation Successfully",
				UPDATEMESSAGE: "Update Occupation Successfully",
				EDIT: "Edit Occupation",
				ADD: "Add Occupation",
				NAME: "Occupation",
				ERROR: "Please Specify Occupation",
				PATTERN: {
					// tslint:disable-next-line: quotemark
					NAME: "(\\(?\\+?[^\\s0-9\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]+(\\s{0,1}[a-zA-Z-, ][^<>\\s])*\\)?)?[^0-9_\\-\\(\\)\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]",
				},
			},
			CMSPAGE: {
				LISTINGTITLE: projectname + " | CMS Page List",
				ADDTITLE: projectname + " | CMS Page Add",
				EDITTITLE: projectname + " | CMS Page Update",
				LIST: "CMS Pages List",
				NAMEPLURAL: "CMS Pages",
				NAMESINGULAR: "CMS Page",
				NEW: "New CMS Page",
				DELETEDSELECTED: "Delete selected CMS Pages",
				UPDATESELECTED: "Update status for selected CMS Pages",
				ADDNEW: "Add New CMS Page",
				UPDATE: "Update CMS Page",
				CREATEMESSAGE: "Create CMS Page Successfully",
				UPDATEMESSAGE: "Update CMS Page Successfully",
				EDIT: "Edit CMS Page",
				ADD: "Add CMS Page",
				NAME: "CMS Page",
				ERROR: "Please Specify CMS Page",
				PATTERN: {
					// tslint:disable-next-line: quotemark
					NAME: "(\\(?\\+?[^\\s0-9\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]+(\\s{0,1}[a-zA-Z-, ][^<>\\s])*\\)?)?[^0-9_\\-\\(\\)\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]",
				},
			},
			FEEDBACK: {
				LISTINGTITLE: projectname + " | Messages List",
				ADDTITLE: projectname + " | Messages Add",
				EDITTITLE: projectname + " | Messages Update",
				LIST: "Messages List",
				NAMEPLURAL: "Messages",
				NAMESINGULAR: "Messages",
				NEW: "New Messages",
				DELETEDSELECTED: "Delete selected Messages",
				UPDATESELECTED: "Update status for selected Messages",
				ADDNEW: "Add New Messages",
				UPDATE: "Update Messages",
				CREATEMESSAGE: "Create Messages Successfully",
				UPDATEMESSAGE: "Update Messages Successfully",
				EDIT: "Edit Messages",
				ADD: "Add Messages",
				NAME: "Messages",
				ERROR: "Please Specify Messages",
				PATTERN: {
					// tslint:disable-next-line: quotemark
					NAME: "(\\(?\\+?[^\\s0-9\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]+(\\s{0,1}[a-zA-Z-, ][^<>\\s])*\\)?)?[^0-9_\\-\\(\\)\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]",
				},
				MESSAGE: "Message",
				SUBJECT: "Subject",
				ATTACHMENT: "Attachment",
			},
			NOTIFICATION: {
				LISTINGTITLE: projectname + " | Notification List",
				ADDTITLE: projectname + " | Notification Add",
				EDITTITLE: projectname + " | Notification Update",
				LIST: "Notification List",
				NAMEPLURAL: "Notifications",
				NAMESINGULAR: "Notification",
				NEW: "New Notification",
				DELETEDSELECTED: "Delete selected Notifications",
				UPDATESELECTED: "Update status for selected Notifications",
				ADDNEW: "Add New Notification",
				UPDATE: "Update Notification",
				CREATEMESSAGE: "Create Notification Successfully",
				UPDATEMESSAGE: "Update Notification Successfully",
				EDIT: "Edit Notification",
				ADD: "Add Notification",
				NAME: "Notification",
				ERROR: "Please Specify Notification",
				SUBJECT: "Subject",
				MESSAGE: "Message",
				ACTIVE: "Active",
				INACTIVE: "Inactive",
				SENT: "Sent",
				SENTERROR: "Error",
				PATTERN: {
					// tslint:disable-next-line: quotemark
					NAME: "(\\(?\\+?[^\\s0-9\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]+(\\s{0,1}[a-zA-Z-, ][^<>\\s])*\\)?)?[^0-9_\\-\\(\\)\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]",
				},
			},
			PACKAGE: {
				LISTINGTITLE: projectname + " | Package List",
				ADDTITLE: projectname + " | Package Add",
				EDITTITLE: projectname + " | Package Update",
				LIST: "Packages List",
				NAMEPLURAL: "packages",
				NAMESINGULAR: "package",
				NEW: "New Package",
				DELETEDSELECTED: "Delete selected Packages",
				UPDATESELECTED: "Update status for selected Packages",
				ADDNEW: "Add New Package",
				UPDATE: "Update Package",
				CREATEMESSAGE: "Create Package Successfully",
				UPDATEMESSAGE: "Update Package Successfully",
				EDIT: "Edit Package",
				ADD: "Add Package",
				NAME: "Package",
				ERROR: "Please Specify Package",
				PRICE: "Price",
				PATTERN: {
					// tslint:disable-next-line: quotemark
					NAME: "(\\(?\\+?[^\\s0-9\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]+(\\s{0,1}[a-zA-Z-, ][^<>\\s])*\\)?)?[^0-9_\\-\\(\\)\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]",
					PRICE: "^d+(,d{3})*(.d{1,2})?$",
				},
			},
			COUNTRY: {
				LISTINGTITLE: projectname + " | Country List",
				ADDTITLE: projectname + " | Country Add",
				EDITTITLE: projectname + " | Country Update",
				COUNTRYLIST: "Countries List",
				COUNTRYNAMEPLURAL: "Countries",
				COUNTRYNAMESINGULAR: "country",
				NEWCOUNTRY: "New Country",
				DELETEDSELECTED: "Delete selected Countries",
				UPDATESELECTED: "Update status for selected Countries",
				ADDNEW: "Add New Country",
				UPDATE: "Update Country",
				CREATEMESSAGE: "Create Country Successfully",
				UPDATEMESSAGE: "Update Country Successfully",
				EDIT: "Edit Country",
				ADD: "Add Country",
				COUNTRY_CODE: "Country Code",
				COUNTRY_NAME: "Country",
				COUNTRY_ERROR: "Please Specify Country",
				PATTERN: {
					// tslint:disable-next-line: quotemark
					NAME: "(\\(?\\+?[^\\s0-9\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]+(\\s{0,1}[a-zA-Z-, ][^<>\\s])*\\)?)?[^0-9_\\-\\(\\)\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]",
				},
			},
			SETTING: {
				LISTINGTITLE: projectname + " | Setting List",
				ADDTITLE: projectname + " | Setting Add",
				EDITTITLE: projectname + " | Setting Update",
				SETTINGLIST: "Settings List",
				SETTINGNAMEPLURAL: "settings",
				SETTINGNAMESINGULAR: "setting",
				NEWSETTING: "New Setting",
				DELETEDSELECTED: "Delete selected Settings",
				UPDATESELECTED: "Update status for selected Settings",
				ADDNEW: "Add New Setting",
				UPDATE: "Update Setting",
				CREATEMESSAGE: "Create Setting Successfully",
				UPDATEMESSAGE: "Update Setting Successfully",
				EDIT: "Edit Setting",
				ADD: "Add Setting",
				VALUES: "Values",
				VALIDATION: {
					SELECTTYPE: "Please Select Setting Type",
				},
			},
			SETTINGTYPE: {
				LISTINGTITLE: projectname + " | Setting Type List",
				ADDTITLE: projectname + " | Setting Type Add",
				EDITTITLE: projectname + " | Setting Type Update",
				SETTINGTYPELIST: "Settings Type List",
				SETTINGTYPENAMEPLURAL: "setting Types",
				SETTINGTYPENAMESINGULAR: "setting Type",
				NEWSETTINGTYPE: "New  Setting Type",
				DELETEDSELECTED: "Delete selected  Setting Types",
				UPDATESELECTED: "Update status for selected  Setting Types",
				ADDNEW: "Add New Setting Type",
				UPDATE: "Update Setting Type",
				CREATEMESSAGE: "Create Setting Type Successfully",
				UPDATEMESSAGE: "Update Setting Type Successfully",
				EDIT: "Edit Setting Type",
				ADD: "Add Setting Type",
				VALIDATION: {
					SELECTTYPE: "Please Select Type",
				},
			},
			STATE: {
				LISTINGTITLE: projectname + " | State List",
				ADDTITLE: projectname + " | State Add",
				EDITTITLE: projectname + " | State Update",
				STATELIST: "States List",
				STATENAMEPLURAL: "States",
				STATENAMESINGULAR: "state",
				NEWSTATE: "New State",
				DELETEDSELECTED: "Delete selected Countries",
				UPDATESELECTED: "Update status for selected Countries",
				ADDNEW: "Add New State",
				UPDATE: "Update State",
				CREATEMESSAGE: "Create State Successfully",
				UPDATEMESSAGE: "Update State Successfully",
				EDIT: "Edit State",
				ADD: "Add State",
				STATE_NAME: "State",
				STATE_ERROR: "Please Specify State",
				DRAG_FROM_LEFT: "Drag from left & drop below to add record",
				PATTERN: {
					// tslint:disable-next-line: quotemark
					NAME: "(\\(?\\+?[^\\s0-9\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]+(\\s{0,1}[a-zA-Z-, ][^<>\\s])*\\)?)?[^0-9_\\-\\(\\)\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]",
				},
			},
			TEMPLATE: {
				LISTINGTITLE: projectname + " | Email Template List",
				ADDTITLE: projectname + " | Email Template Add",
				EDITTITLE: projectname + " | Email Template Update",
				TEMPLATELIST: "Email Templates List",
				TEMPLATENAMEPLURAL: "Email Templates",
				TEMPLATENAMESINGULAR: "Email Template",
				NEWTEMPLATE: "New Email Template",
				DELETEDSELECTED: "Delete selected Countries",
				UPDATESELECTED: "Update status for selected Countries",
				ADDNEW: "Add New Email Template",
				UPDATE: "Update Email Template",
				CREATEMESSAGE: "Create Email Template Successfully",
				UPDATEMESSAGE: "Update Email Template Successfully",
				EDIT: "Edit Email Template",
				ADD: "Add Email Template",
				TEMPLATE_NAME: "Email Template",
				TEMPLATE_ERROR: "Please Specify Email Template",
				DRAG_FROM_LEFT: "Drag from left & drop below to add record",
				TITLE: "Title",
				PATTERN: {
					// tslint:disable-next-line: quotemark
					NAME: "(\\(?\\+?[^\\s0-9\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]+(\\s{0,1}[a-zA-Z-, ][^<>\\s])*\\)?)?[^0-9_\\-\\(\\)\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]",
				},
			},
			CITY: {
				LISTINGTITLE: projectname + " | City List",
				ADDTITLE: projectname + " | City Add",
				EDITTITLE: projectname + " | City Update",
				CITYLIST: "Cities List",
				CITYNAMEPLURAL: "Cities",
				CITYNAMESINGULAR: "city",
				NEWCITY: "New City",
				DELETEDSELECTED: "Delete selected Countries",
				UPDATESELECTED: "Update status for selected Countries",
				ADDNEW: "Add New City",
				UPDATE: "Update City",
				CREATEMESSAGE: "Create City Successfully",
				UPDATEMESSAGE: "Update City Successfully",
				EDIT: "Edit City",
				ADD: "Add City",
				CITY_NAME: "City",
			},
			WARD: {
				LISTINGTITLE: projectname + " | Ward List",
				ADDTITLE: projectname + " | Ward Add",
				EDITTITLE: projectname + " | Ward Update",
				WARDLIST: "Ward List",
				WARDNAMEPLURAL: "Wards",
				WARDNAMESINGULAR: "ward",
				NEWWARD: "New Ward",
				DELETEDSELECTED: "Delete selected Countries",
				UPDATESELECTED: "Update status for selected Countries",
				ADDNEW: "Add New Ward",
				UPDATE: "Update Ward",
				CREATEMESSAGE: "Create Ward Successfully",
				UPDATEMESSAGE: "Update Ward Successfully",
				EDIT: "Edit Ward",
				ADD: "Add Ward",
			},
			CONVENIENCE: {
				LISTINGTITLE: projectname + " | Public Convenience List",
				ADDTITLE: projectname + " | Public Convenience Add",
				EDITTITLE: projectname + " | Public Convenience Update",
				CONVENIENCELIST: "Public Convenience List",
				CONVENIENCENAMEPLURAL: "Public Convenience",
				CONVENIENCENAMESINGULAR: "public convenience",
				NEWCONVENIENCE: "New Public Convenience",
				DELETEDSELECTED: "Delete selected Countries",
				UPDATESELECTED: "Update status for selected Countries",
				ADDNEW: "Add New Public Convenience",
				UPDATE: "Update Public Convenience",
				CREATEMESSAGE: "Create Public Convenience Successfully",
				UPDATEMESSAGE: "Update Public Convenience Successfully",
				EDIT: "Edit Public Convenience",
				ADD: "Add Public Convenience",
			},
			DISTRICT: {
				LISTINGTITLE: projectname + " | District List",
				ADDTITLE: projectname + " | District Add",
				EDITTITLE: projectname + " | District Update",
				DISTRICTLIST: "Districts List",
				DISTRICTNAMEPLURAL: "Districts",
				DISTRICTNAMESINGULAR: "district",
				NEWDISTRICT: "New District",
				DELETEDSELECTED: "Delete selected Districts",
				UPDATESELECTED: "Update status for selected Districts",
				ADDNEW: "Add New District",
				UPDATE: "Update District",
				CREATEMESSAGE: "Create District Successfully",
				UPDATEMESSAGE: "Update District Successfully",
				EDIT: "Edit District",
				ADD: "Add District",
			},
			ZONE: {
				LISTINGTITLE: projectname + " | Zone List",
				ADDTITLE: projectname + " | Zone Add",
				EDITTITLE: projectname + " | Zone Update",
				ZONELIST: "Zone List",
				ZONENAMEPLURAL: "Zone",
				ZONENAMESINGULAR: "Zone",
				NEWZONE: "New Zone",
				DELETEDSELECTED: "Delete selected Zone",
				UPDATESELECTED: "Update status for selected Zone",
				ADDNEW: "Add New Zone",
				UPDATE: "Update Zone",
				CREATEMESSAGE: "Create Zone Successfully",
				UPDATEMESSAGE: "Update Zone Successfully",
				EDIT: "Edit Zone",
				ADD: "Add Zone",
			},
			LANGUAGE: {
				LISTINGTITLE: projectname + " | Language List",
				ADDTITLE: projectname + " | Language Add",
				EDITTITLE: projectname + " | Language Update",
				LANGUAGELIST: "Languages List",
				LANGUAGENAMEPLURAL: "Languages",
				LANGUAGENAMESINGULAR: "language",
				NEWLANGUAGE: "New Language",
				DELETEDSELECTED: "Delete selected Languages",
				UPDATESELECTED: "Update status for selected Languages",
				ADDNEW: "Add New Language",
				UPDATE: "Update Language",
				CREATEMESSAGE: "Create Language Successfully",
				UPDATEMESSAGE: "Update Language Successfully",
				EDIT: "Edit Language",
				ADD: "Add Language",
				LOCALE: "Locale",
				LOCALE_REQUIRED: "Locale Required",
				LOCALE_INVALID: "Locale contains invalid characters",
			},
			USERS: {
				LISTINGTITLE: projectname + " | Team Members",
				USERSLIST: "Team Members",
				NEWUSER: "New Member",
				USERNAMEPLURAL: "users",
				USERNAMESINGULAR: "user",
				DELETEDSELECTED: "Delete selected Member",
				UPDATESELECTED: "Update status for selected Member",
				ADDNEW: "Add Member",
				UPDATE: "Update Member",
				PASSWORD: "Password",
				CONFIRMPASSWORD: "Confirm Password",
				USERSUCCESS: "Member Added Successfully",
				FILTERUSERGROUP: "Filter Member Group",
				ADDTITLE: projectname + " | Member Add",
				EDITTITLE: projectname + " | Member Update",
				EDIT: "Edit Member",
				ADD: "Add Member",
			},
			ARTISTE: {
				LISTINGTITLE: projectname + " | Talents",
				ARTISTELIST: "Talents",
				NAME: "Name",
				LABLE:"Lable",
				NAMEREQ: "Please specify Name",
				NAMEERROR: "Name is Invalid",
				PROFESSION: "Profession",
				MANAGEMENT: "Management",
				MANAGEMENTREQ: "Please enter management",
				NEW: "New Talent",
				USERNAMEPLURAL: "talents",
				USERNAMESINGULAR: "talent",
				DELETEDSELECTED: "Delete selected Talents",
				UPDATESELECTED: "Update status for selected Talents",
				ADDNEW: "Add New Talent",
				UPDATE: "Update Talent",
				PASSWORD: "Password",
				CONFIRMPASSWORD: "Confirm Password",
				ARTISTEUCCESS: "Talent Added Successfully",
				FILTERUSERGROUP: "Filter Talent Group",
				ADDTITLE: projectname + " | Talents Add",
				EDITTITLE: projectname + " | Talents Update",
				EDIT: "Edit Talent",
				ADD: "Add Talent",
				FACEBOOKURL: "Facebook Link",
				TWITTERURL: "Twitter Link",
				INSTAGRAMURL: "Instagram Link",
				YOUTUBEURL: "Youtube Link",
				WIKIPEDIAURL: "Wikipedia Link",
				SNAPCHATURL: "Snapchat Link",
				CUSTOMURL: "Custom Link",
				PENDING: "Requested",
				SOCIALLINK: "Social Link",
				EXPIRED: "Expired",
				USED: "Used",
				EXPORT: "Export",
				IMPORT: "Import",
				IMPORTA: "Talents Import",
				PRESSKITEMAILID: "Email Id (Press Kit Request)",
			},
			CUSTOMER: {
				LISTINGTITLE: projectname + " | Users",
				CUSTOMERLIST: "Industry Professionals",
				NAME: "Name",
				NAMEREQ: "Please specify Name",
				NAMEERROR: "Name is Invalid",
				PROFESSION: "Profession",
				INTERESTEDIN: "Interested IN",
				OCCUPATION: "Occupation",
				MANAGEMENTREQ: "Please enter management",
				OCCUPATIONREQ: "Please specify Occupation",
				INTERESTEDINRED: "Please specify Interested",
				NEW: "New User",
				DESIGNATION: "Designation",
				COMPANY: "Company",
				DESIGNATIONREQ: "Please specify Designation",
				USERNAMEPLURAL: "users",
				USERNAMESINGULAR: "user",
				DELETEDSELECTED: "Delete selected Users",
				UPDATESELECTED: "Update status for selected Users",
				ADDNEW: "Add New User",
				UPDATE: "Update User",
				PASSWORD: "Password",
				CONFIRMPASSWORD: "Confirm Password",
				ARTISTEUCCESS: "Talents Added Successfully",
				FILTERUSERGROUP: "Filter User Group",
				ADDTITLE: projectname + " | User Add",
				EDITTITLE: projectname + " | User Update",
				EDIT: "Edit User",
				ADD: "Add User",
			},
			MANAGE: {
				LISTINGTITLE: projectname + " | Manage",
				MANAGELIST: "Official Representatives",
				NAME: "Name",
				NAMEREQ: "Please specify Name",
				NAMEERROR: "Name is Invalid",
				PROFESSION: "Profession",
				INTERESTEDIN: "Interested IN",
				OCCUPATION: "Occupation",
				MANAGEMENTREQ: "Please enter management",
				OCCUPATIONREQ: "Please specify Occupation",
				INTERESTEDINRED: "Please specify Interested",
				NEW: "New User",
				DESIGNATION: "Designation",
				COMPANY: "Company",
				DESIGNATIONREQ: "Please specify Designation",
				USERNAMEPLURAL: "users",
				USERNAMESINGULAR: "user",
				DELETEDSELECTED: "Delete selected Users",
				UPDATESELECTED: "Update status for selected Users",
				ADDNEW: "Add New User",
				UPDATE: "Update User",
				PASSWORD: "Password",
				CONFIRMPASSWORD: "Confirm Password",
				ARTISTEUCCESS: "Talents Added Successfully",
				FILTERUSERGROUP: "Filter User Group",
				ADDTITLE: projectname + " | User Add",
				EDITTITLE: projectname + " | User Update",
				EDIT: "Edit Mangers",
				ADD: "Add Mangers",
			},
			PROFILEREQUEST: {
				LISTINGTITLE: projectname + " | Profile Update",
				LIST: "Profile Update",
				NAME: "Name",
				NAMEREQ: "Please specify Name",
				NAMEERROR: "Name is Invalid",
				PROFESSION: "Profession",
				OCCUPATION: "Occupation",
				MANAGEMENTREQ: "Please enter management",
				NEW: "New Profile",
				USERNAMEPLURAL: "profiles",
				USERNAMESINGULAR: "profile",
				DELETEDSELECTED: "Delete selected Profile",
				UPDATESELECTED: "Update status for selected Profile",
				ADDNEW: "Add New Profile",
				UPDATE: "Update Profile",
				PASSWORD: "Password",
				CONFIRMPASSWORD: "Confirm Password",
				ADDEDSUCCESS: "Profile Added Successfully",
				UPDATESUCCESS: "Profile Updated Successfully",
				FILTERUSERGROUP: "Filter Profile Group",
				ADDTITLE: projectname + " | Profile Add",
				EDITTITLE: projectname + " | Profile Update",
				EDIT: "Edit Profile",
				ADD: "Add Profile",
				APPROVED: "Approved",
				REJECT: "Reject",
				PENDING: "New Profile",
				REJECTED: "Rejected",
				USED: "Used",
			},
			SURVEY: {
				LISTINGTITLE: projectname + " | Survey List",
				SURVEYLIST: "Survey List",
				NEWSURVEY: "New Survey",
				SURVEYNAMEPLURAL: "survey",
				SURVEYNAMESINGULAR: "survey",
				DELETEDSELECTED: "Delete selected Surveyor",
				UPDATESELECTED: "Update status for selected Surveyor",
				ADDNEW: "Add New Survey",
				UPDATE: "Update Survey",
				PASSWORD: "Password",
				CONFIRMPASSWORD: "Confirm Password",
				SURVEYSUCCESS: "Survey Added Successfully",
				FILTERSURVEYGROUP: "Filter Survey Group",
				ADDTITLE: projectname + " | Surveyor Add",
				EDITTITLE: projectname + " | Surveyor Update",
				EDIT: "Edit Survey",
				ADD: "Add Survey",
			},
			COUPONCODE: {
				// LISTINGTITLE: projectname + ' | Coupon Codes',
				LISTINGTITLE: projectname + " | Promo codes",
				LIST: "Promo codes",
				NAME: "Name",
				NAMEREQ: "Please specify Name",
				NAMEERROR: "Name is Invalid",
				PROFESSION: "Profession",
				MANAGEMENT: "Management",
				MANAGEMENTREQ: "Please enter management",
				NEW: "New Promo",
				PLURAL: "Promos",
				SINGULAR: "Promo",
				DELETEDSELECTED: "Delete selected Promos",
				UPDATESELECTED: "Update status for selected Promos",
				ADDNEW: "Add New Promo",
				UPDATE: "Update Promo",
				PASSWORD: "Password",
				CONFIRMPASSWORD: "Confirm Password",
				ARTISTEUCCESS: "Promo Added Successfully",
				FILTERUSERGROUP: "Filter Promo Group",
				ADDTITLE: projectname + " | Promos Add",
				EDITTITLE: projectname + " | Promos Update",
				EDIT: "Edit Promo",
				ADD: "Add Promo",
			},
			USERGROUP: {
				// LISTINGTITLE: projectname + ' | Team Roles',
				LISTINGTITLE: projectname + " | Roles",
				// USERGROUPLIST : 'Team Roles',
				USERGROUPLIST: "Roles",
				USERGROUPNAMEPLURAL: "usergroups",
				USERGROUPNAMESINGULAR: "usergroup",
				NEWUSERGROUP: "New Role",
				DELETEDSELECTED: "Delete selected Role",
				UPDATESELECTED: "Update status for selected Roles",
				ADDNEW: "Add New Role",
				UPDATE: "Update Role",
				ADDTITLE: projectname + " | Add Role",
				EDITTITLE: projectname + " | Update Role",
			},
			QUESTION: {
				LISTINGTITLE: projectname + " | Question List",
				ADDTITLE: projectname + " | Question Add",
				EDITTITLE: projectname + " | Question Update",
				QUESTIONLIST: "Questions List",
				QUESTIONNAMEPLURAL: "Questions",
				QUESTIONNAMESINGULAR: "Question",
				NEWQUESTION: "New Question",
				DELETEDSELECTED: "Delete selected Questions",
				UPDATESELECTED: "Update status for selected Questions",
				ADDNEW: "Add New Question",
				UPDATE: "Update Question",
				CREATEMESSAGE: "Create Question Successfully",
				UPDATEMESSAGE: "Update Question Successfully",
				EDIT: "Edit Question",
				ADD: "Add Question",
				SINGLE_CHOICE: "Single choice",
				MULTIPLE_CHOICE: "Multiple choice",
				QUESTION_IMAGES: "Question Images",
				ANSWER_LIMIT: "Answer Option Limit",
				CHOICE: "Choice",
				NUMERIC: "Numeric",
				DATE: "Date",
				TEXT: "Text",
				IMAGE: "Image ",
				BOTH: "Both",
				MULTIPLE_ANSWER: "Multiple answer",
				MULTIPLE_LABLE_MULTIPLE_ANSWER: "Multiple lable Multiple answer",
				ANSWER_INPUT: "Answer input",
				ATTACH_FILES: "Attach files",
				QUESTION_TEXT: "Question text",
				ANSWER_TYPE: "Answer type",
				ANSWER_IMAGE: "Answer image",
				MIN_RANGE: "Min range",
				MAX_RANGE: "Max range",
				ANSWER: "Answer",
				QUESTION: "Question",
				SEQUENCE: "Sequence",
				ENTER_SEQUENCE: "Please enter sequence",
				INVALID_SEQUENCE: "Invalid sequence",
				ADD_OPTION: "Add",
				CUSTOME: "Custome",
				ADD_MAXIMUM_OF: "You can add maximum of",
				ENTER_ANSWER: "Please enter answer",
				INVALID_ANSWER: "Invalid answer",
				EXCLUSIVE_RESPONSE: "Mutually Exclusive Response",
				ENTER_MAXIMUM_RANGE: "Please enter maximum range",
				Maximum_number_minimum_number: "Maximum number is not less than to minimum number",
				ENTER_MINIMUM_RANGE: "Please enter minimum range",
				INVALID_RANGE: "Invalid range(numeric value, minimum length is 1 and maximum length is 5)",
				PLEASEENTERQUESTION: "Please enter question",
				INVALID_QUESTION: "Invalid question",
				QUESTIONMUSTBEATLEAST: "Question must be at least 3 characters long and space is not allowed",
				MAX_UPLOAD_SIZE: "Max Upload size is 5MB. Allowed file types are jpg , png, jpeg. you can upload maximum  question image size must be 228px * 200px",
				MAX_UPLOAD_SIZE_ADD: "Max Upload size is 5MB. Allowed file types are jpg , png, jpeg. Image size must be 228px * 200px",
				REVISION_FOR_THIS_QUESTION: "Do you want to create revision for this question?",
				CREATEMAPPINGMESSAGE: "Create Question Mapping Successfully",
				UPDATEMAPPINGMESSAGE: "Update Question Mapping Successfully",
				DELETEMAPPINGMESSAGE: "Delete Question Mapping Successfully",
				UPLOAD_ONLY: "You can upload only",
				CONDITIONISMAPPED: "You can not change order as condition is mapped!!",
			},
			MENU: {
				LISTINGTITLE: projectname + " | Menu List",
				ADDTITLE: projectname + " | Menu Add",
				EDITTITLE: projectname + " | Menu Update",
				MENULIST: "Menus List",
				MENUNAMEPLURAL: "Menu",
				MENUNAMESINGULAR: "Menu",
				NEWMENU: "New Menu",
				DELETEDSELECTED: "Delete selected Menus",
				UPDATESELECTED: "Update status for selected Menus",
				ADDNEW: "Add New Menu",
				UPDATE: "Update Menu",
				CREATEMESSAGE: "Create Menu Successfully",
				UPDATEMESSAGE: "Update Menu Successfully",
				EDIT: "Edit Menu",
				ADD: "Add Menu",
				VALUES: "Values",
				PARENT: "Parent",
				MODULE: "Module",
				PAGE: "Page",
				ICON: "Icon",
				ORDERING: "Ordering",
				VALIDATION: {
					SELECTTYPE: "Please Select Menu Type",
				},
			},
			ACCESSLOGS: {
				LISTINGTITLE: projectname + " | Access Logs List",
				ADDTITLE: projectname + " | Access Logs Add",
				EDITTITLE: projectname + " | Access Logs Update",
				ACCESSLOGSLIST: "Access Logs List",
				ACCESSLOGSNAMEPLURAL: "Access Logs",
				ACCESSLOGSNAMESINGULAR: "Access Logs",
				NEWACCESSLOGS: "New Access Logs",
				DELETEDSELECTED: "Delete selected Access Logs",
				UPDATESELECTED: "Update status for selected Access Logs",
				ADDNEW: "Add New Access Logs",
				UPDATE: "Update Access Logs",
				CREATEMESSAGE: "Create Access Logs Successfully",
				UPDATEMESSAGE: "Update Access Logs Successfully",
				EDIT: "Edit Access Logs",
				ADD: "Add Access Logs",
				VALUES: "Values",
				PARENT: "Parent",
				MODULE: "Module",
				PAGE: "Page",
				ICON: "Icon",
				ORDERING: "Ordering",
				VALIDATION: {
					SELECTTYPE: "Please Select Access Logs Type",
				},
			},
			CATEGORY: {
				LISTINGTITLE: projectname + " | Talents Professions",
				ADDTITLE: projectname + " | Profession Add",
				EDITTITLE: projectname + " | Profession Update",
				CATEGORYLIST: "Talent Professions",
				CATEGORYNAMEPLURAL: "Profession",
				CATEGORYNAMESINGULAR: "Profession",
				NEWCATEGORY: "New Profession",
				DELETEDSELECTED: "Delete selected Profession",
				UPDATESELECTED: "Update status for selected Profession",
				ADDNEW: "Add New Profession",
				UPDATE: "Update Profession",
				CREATEMESSAGE: "Create Profession Successfully",
				UPDATEMESSAGE: "Update Profession Successfully",
				EDIT: "Edit Profession",
				ADD: "Add Profession",
				VALUES: "Values",
				PARENT: "Parent",
				MODULE: "Module",
				PAGE: "Page",
				ICON: "Icon",
				ORDERING: "Ordering",
				POPULAR: "Popular",
				VALIDATION: {
					SELECTTYPE: "Please Select Profession Type",
					TITLE: "(\\(?\\+?[^\\s0-9\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]+(\\s{0,1}[a-zA-Z-, ][^<>\\s])*\\)?)?[^0-9_\\-\\(\\)\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]",
				},
			},
			MANAGER: {
				LISTINGTITLE: projectname + " | Managers",
				ADDTITLE: projectname + " | Manager Add",
				EDITTITLE: projectname + " | Manager Update",
				LIST: "Managers",
				NAMEPLURAL: "Manager",
				NAMESINGULAR: "Manager",
				NEW: "New Manager",
				EMAILREQ: "Please specify email",
				EMAILERROR: "Email is Invalid",
				DELETEDSELECTED: "Delete selected Manager",
				UPDATESELECTED: "Update status for selected Manager",
				ADDNEW: "Add New Manager",
				UPDATE: "Update Manager",
				CREATEMESSAGE: "Create Manager Successfully",
				UPDATEMESSAGE: "Update Manager Successfully",
				MANGERINVITAIONMESSAGE: "Invitation Sent Successfully",
				EDIT: "Edit Manager",
				ADD: "Add Manager",
				VALUES: "Values",
				PARENT: "Parent",
				MODULE: "Module",
				PAGE: "Page",
				ICON: "Icon",
				ORDERING: "Ordering",
				VALIDATION: {
					SELECTTYPE: "Please Select Manager Type",
					TITLE: "(\\(?\\+?[^\\s0-9\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]+(\\s{0,1}[a-zA-Z-, ][^<>\\s])*\\)?)?[^0-9_\\-\\(\\)\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]",
				},
			},
			SUBSCRIPTION: {
				LISTINGTITLE: projectname + " | Subscriptions",
				ADDTITLE: projectname + " | Subscription Add",
				EDITTITLE: projectname + " | Subscription Update",
				LIST: "Subscriptions",
				PLURAL: "Subscription",
				SINGULAR: "Subscription",
				NEW: "New Subscription",
				EMAILREQ: "Please specify email",
				EMAILERROR: "Email is Invalid",
				DELETEDSELECTED: "Delete selected Subscription",
				UPDATESELECTED: "Update status for selected Subscription",
				ADDNEW: "Add New Subscription",
				UPDATE: "Update Subscription",
				CREATEMESSAGE: "Create Subscription Successfully",
				UPDATEMESSAGE: "Update Subscription Successfully",
				EDIT: "Edit Subscription",
				ADD: "Add Subscription",
				VALUES: "Values",
				PARENT: "Parent",
				MODULE: "Module",
				PAGE: "Page",
				ICON: "Icon",
				ORDERING: "Ordering",
				COUPONCODE: "Promo code",
				TRANSACTION: "Transaction",
				PAYMENTTYPE: "Payment Type",
				EXPIREON: "Expire On",
				VALIDATION: {
					SELECTTYPE: "Please Select Subscription Type",
					TITLE: "(\\(?\\+?[^\\s0-9\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]+(\\s{0,1}[a-zA-Z-, ][^<>\\s])*\\)?)?[^0-9_\\-\\(\\)\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]",
				},
			},
			COMMON: {
				WEBPASSWORD: "Reset Web Password",
				WEBEMAIL: "Webemail",
				HOME: "Home",
				ALL: "All",
				ACTIVE: "Active",
				INACTIVE: "Inactive",
				PENDING: "Pending",
				APPROVE: "Approve",
				PLEASESELECT: "Please Select",
				BASICINFORMATION: "Basic Information",
				COMPANYMANAGEMENT: "Company Management",
				COMPANYREGISTEARS: "Company Registrars",
				CREATEDAT: "Created Date",
				STATUS: "Status",
				USERTYPESTATUS:"UserType",
				ACTION: "Action",

				SHOWINSEARCH: "Show in Search",
				DAILYCONNECTIONLIMIT: "Connection Limit",
				PROFESSIONS: "Profession",
				DELETE: "Delete",
				TITLE: "Title",
				VIEW: "View",
				UPDATE: "Edit",
				PARENT: "Parent",
				PIC: "Pic",
				SEARCHBTN: "Search",
				NAMEREQ: "Please specify Name",
				NAMEERROR: "Name is Invalid",
				FIRSTNAME: "First Name ",
				FIRSTNAMEREQ: "Please specify First Name",
				FIRSTNAMEERROR: "First Name is Invalid",
				NAME: "Name",
				LASTNAME: "Last Name",
				LASTNAMEREQ: "Please specify Last Name",
				LASTNAMEERROR: "Last Name is Invalid",
				EMAIL: "Email",
				EMAILREQ: "Please specify Email",
				EMAILERROR: "Email is Invalid",
				WEBSITE: "Website",
				WEBSITEREQ: "Please specify Website",
				WEBSITEERROR: "Website is Invalid",
				FAX: "Fax",
				FAXERROR: "Fax is Invalid",
				ADDRESS: "Address",
				MOBILENUMBER: "Mobile Number",
				DATEOFBIRTH: "Date of Birth",
				DATEOFBIRTHERROR: "Please specify Date of Birth",
				SUBMITBTN: "Save",
				SEARCH: "Search",
				BACKBTN: "Back",
				UPDATEBTN: "Update",
				APPROVEBTN: "Approve",
				RESETBTN: "Reset",
				CANCELBTN: "Cancel",
				REPORT: "Report",
				CITY: "City",
				COUNTRY: "Country",
				CODE: " Code",
				SELECTCOUNTRY: "Select Country",
				SELECTREGION: "Select Region",
				SELECTSTATE: "Select State",
				SELECTCITY: "Select City",
				SELECTUSERGROUP: "Select UserGroup",
				STATE: "State",
				TEMPLATE: "Email Template",
				PIN: "Pin",
				ZIPCODE: "Zip code",
				NORECORDS: "No record(s) found",
				DELETEALL: "Delete All",
				UPDATESTATUS: "Update status",
				UPDATEUSERTYPE: "Update User Type",
				DESCRIPTION: "Description",
				DESIGNATION: "Designation",
				ENTERDESCRIPTION: "Please Enter Description",
				STATUSUPDATED: "status has been updated successfully",
				SUCCESS: "Successfully",
				SELECTED: "Selected",
				STATUSSELECTEDUPDATE: "Status has been updated for selected",
				SELECTEDUSERTYPEUPDATE: "Edit user type",
				PERMANTLYDELETE: "Are you sure to permanently delete this",
				ISDELETING: "is deleting",
				DELETESELECTED: "Delete Selected",
				UPDATESELECTED: "Update Status for Selected",
				AMOUNT: "Amount",
				TIME: "Time",
				VALUE: "Value",
				USERGROUP: "User Group",
				SELECT: "Select",
				VERSION: "Version",
				TYPE_THREE_LETTERS: "Type three letters and wait some time",
				PERMISSION: "Permissions",
				LABELSEARCH: "Type Label Here",
				SELECTANYONE: "Select any one",
				INVALIDFILE: "You have uploaded an invalid file type",
				PASSWORDHINT: "contains 8-20 characters, at least one number, at least  one upper character, at least one lower character, at least one special character.",
				OOPS: "Oops!",
				SUBJECT: "Subject",
				ERROR: "Error!",
				IMGERROR : "Error: Only PNG file are allowed.",
				ERRORS: "Something went wrong",
				NEXT: "Next",
				SELECTZONE: "Please select zone",
				SELECTWARD: "Please select ward",
				SELECTCONVENIENCE: "Please select Public Convenience",
				WARD: "Ward",
				PREVIOUS: "Previous",
				SETTINGTYPE: "Setting type",
				SELECTDATE: "Please select date",
				ENTERVALUE: "Please Enter Value",
				UPLOADIMAGE: "Please upload image",
				HELLO: "Hello!",
				WELCOMEBACK: "Welcome Back",
				QUESTIONTEXT: "It looks like you couldn`t complete the previous participation. Would you like to continue from where you left or would you like to start a new survey question?",
				CONTINUEWITH: "Continue With",
				OR: "or",
				PREVIOUSQUESTION: "Previous Question",
				FRESHQUESTION: "Fresh Question",
				CONFIRM: "Confirm",
				AREYOUSURE: "Are you sure will start first?",
				YES: "yes",
				NO: "No",
				FILTERCATEGORY: "Filter Category",
				ALLCLUSTERS: "All Clusters",
				CLEAR: "Clear",
				MAPQUESTIONNAIRE: "Map questionnaire",
				SURVEYQUESTIONTITLE: "Survey of Public Conveniences",
				VALIDATION: {
					NAME_INVALID: "Name contains invalid characters",
					NAME_REQUIRED: "Please specify name",
					TEMPLATE_REQUIRED: "Email Template can not be empty",
					USERGROUPREQUIRED: "Please specify User Group",
					NAME_MIN_LENGTH: "Name must be at least 2 characters long.",
					NAME_MAX_LENGTH: "cannot exceed more than 50 characters.",
					ZIP_REQUIRED: "Please specify Zipcode",
					ZIP_INVALID: "Zipcode is invalid",
					CITY_REQUIRED: "Please specify City",
					STATE_REQUIRED: "Please specify State",
					REGION_REQUIRED: "Please specify Region",
					COUNTRY_REQUIRED: "Please specify Country",
					VALUE_REQUIRED: "Please specify value",
					TITLE_INVALID: "Title contains invalid characters",
					TITLE_REQUIRED: "Please specify title",
					TITLE_MIN_LENGTH: "Title must be at least 2 characters long.",
					TITLE_MAX_LENGTH: "cannot exceed more than 50 characters.",
					SUBJECT_INVALID: "Subject contains invalid characters",
					SUBJECT_REQUIRED: "Please specify Subject",
					SUBJECT_MIN_LENGTH: "Subject must be at least 2 characters long.",
					SUBJECT_MAX_LENGTH: "cannot exceed more than 50 characters.",
					PIN_REQUIRED: "Please specify Pin",
					PIN_REQUIRED_MIN_LENGTH: "Pin must be at least 5 characters long.",
					PIN_REQUIRED_MAX_LENGTH: "cannot exceed more than 6 characters.",
					LATITUDE_REQUIRED: "Please specify latitude",
					LONGITUDE_REQUIRED: "Please specify Longitude",
					PRICE_REQUIRED: "Please specify price",
					PRICE_INVALID: "Price contains invalid characters",
					PROFESSIONREQUIRED: "Please specify artiste profession",
				},
				PATTERN: {
					// tslint:disable-next-line: quotemark
					// NAME : "[a-zA-Z '.&:_()-]*'"
					// tslint:disable-next-line: quotemark
					NAME: "(\\(?\\+?[^\\s0-9\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]+(\\s{0,1}[a-zA-Z-, ][^<>\\s])*\\)?)?[^0-9_\\-\\(\\)\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]",
					// tslint:disable-next-line: quotemark
					EMAIL: "^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$",
					// tslint:disable-next-line: quotemark
					ADSH: "[0-9-]{20}",
					// tslint:disable-next-line: quotemark
					SIC: "[0-9]{2,50}",
					// tslint:disable-next-line: quotemark
					NUMBER_ONLY: "[0-9]+",
					// tslint:disable-next-line: quotemark
					FNAME_LNAME: "^[a-z|A-Z]+(?: [a-z|A-Z]+)*$", // "^[a-zA-Z]+\\s?[a-zA-Z]+$",
					// tslint:disable-next-line: quotemark
					CONTACT_NO: "[0-9]{10}",
					// tslint:disable-next-line: quotemark
					TAG: "[a-zA-Z']*",
					// tslint:disable-next-line: quotemark
					AMOUNT: "[0-9.]+",
					// tslint:disable-next-line: quotemark
					TIME: "^([0-9]|0[0-9]|1[0-9]|2[0-3])?(:([0-5]|[0-5][0-9])?)?$",
					MENUNAME: "[^.\\s][^\\!\\@\\#\\$\\%\\^\\&\\*\\(\\)\\_\\+]*",
					PUBLICCONVENIENCENAME: "[a-zA-Z0-9\\-\\s]+",
					INDIAN_NUMBER: "(\\+?\\d[\\d -]{8,12}\\d)",
					URL_CHECK: "^(https?:\\/\\/)?([\\da-zA-Z\\.-]+\\.[a-zA-Z\\.]{2,6}|[\\d\\.]+)([\\/:?=&#]{1}[\\da-zA-Z\\.-]+)*[\\/\\?]?$",
				},
				PATTERNERROR: {
					INDIAN_NUMBER_ERROR: "Number is Invalid",
					EMAIL_ERROR: "Email is Invalid",
					REQUIRED_ERROR: "This field is Required",
					URL_ERROR: "This URL is Invalid",
				},
			},
			PROFILE: {
				NAME: "My Profile",
				LISTINGTITLE: projectname + " | My Profile",
				PROFILESUCCESS: "Profile updated Successfully!!",
				INVALID_IMAGE: "You have uploaded an invalid file type or Maximum upload file size: 5 MB!",
				PROFILE_IMAGE: "Profile Image",
				BASIC_INFO: "Basic info",
				PHONE: "Mobile Number",
				REJECT_REASON: "Reject Reason", 
				PHONEREQ: "Mobile Number is Required",
				PHONEERROR: "Mobile Number is Invalid",
				ADDRESS: "Enter Address",
				ADDRESSTAB: "Address",
				CHANGEPASSWORD: "Change Password",
				RESIDENTIALADDREDSS: "Residential Address",
				PASSWORDNOTMATCH: "Oh snap! Password not match.",
				PASSWORDREQUIRED: "Password is required",
				OLDPASSWORDREQUIRED: "Old Password is required",
				ENTEROLDPASSWORD: "Old Password",
				NEWPASSWORDREQUIRED: "New Password is required",
				ENTERNEWPASSWORD: "New Password",
				INVALIDNEWPASSWORD: "New Password is not valid",
				INVALIDPASSWORD: "Password is not valid",
				NEWPASSWORDSAME: "New Password should not be same as Old Password",
				CONFIRMPASSWORD: "Confirm Password",
				CONFIRMPASSWORDREQUIRED: "Confirm Password is required",
				CONFIRMPASSWORDSAME: "New password and Confirm password values do not match.",
				INVALIDOLDPASSWORD: "Old Password is not valid",
				CREATEUSERGROUP: "Create User Group Successfully",
				UPDATEUSERGROUP: "Update User Group Successfully",
				FACEBOOKURL: "Facebook Profile URL",
				FACEBOOKURLREQ: "Facebook URL is required",
			},
			CHART: {
				PUBLIC: "Public Toilet",
				COMMUNITY: "Community Toilet",
				BOTH: "Public & Community Toilet",
				YES: "Yes",
				NO: "No",
				SECTION: "In Section",
				SEPRATEFACILITY: "Seprate Facility",
				FUNCTIONAL: "Functional",
				NONFUNCTIONAL: "Non Functional",
				IDEAL: "Ideal",
				UNDERUTILIZED: "Under-utilized",
				OVERUTILIZED: "Over-utilized",
				FUNCTIONALFLUSH: "Functional Flush in Toilet Seats",
				FUCTIONALTAP: "Functional Taps in toilet seat",
				MUGCUBICLES: "Mugs in  Cubicles",
				HOOKSCHANGING: "Hooks for Changing Clothes",
				VANTILATOR: "Ventilator/Exhaust",
				SEPRATELIGHT: "Separate light in cubicle",
				DEFICIT: "Deficit",
				CHILDFRIENDLYWASHBASIN: "Child friendly Wash Basin",
				CHILDFRIENDLYTOILET: "Child Friendly Toilet Set",
				FUNCTIONALDOOR: "Functional Door & Latches",
				CHILDURINALPOT: "Child Urinal Pot",
				DISABLEDFRIENDLY: "Intact disabled friendly Toilet seat",
				RAMPWITHHAND: "Ramp with hand rail",
				DISABLEDFRIENDLYCUBICLE: "Disabled friendly cubicle size",
				GRABBAR: "Grab bar",
				BARRIERFREE: "Barrier Free Entrance",
				WASHBASINDISABLEDFRIENDLY: "Washbasin at Disabled Friendly height",
				UNBROKENDOOR: "Unbroken Door with proper latch",
				ULB: "ULB",
				PVT: "Pvt Agency",
				GOOD: "Good",
				FAIR: "Fair",
				BAD: "Bad",
				ONCEDAY: "Once a day",
				TWICEDAY: "Twice a day",
				THRICEDAY: "Thrice a day",
				ASMANYREQUIRED: "As many times as required",
				IRREGULAR: "Irregular",
				NEVER: "Never",
				CHECKEDANDREPAIRED: "Checked and repaired on regular basis ",
				WHENREQUIRED: "As and when required",
				NOTREPAIRED: "Not Repaired",
				OPENINGHOUR: "During opening hour",
				FEWHOUR: "Few hrs a day",
				NOTFIXED: "Not fixed",
				FUNCTIONALTAPCUBICLE: "Functional tap in cubicle",
				STORAGETANK: "Water to be fetched from storage tank",
				OWNHOUSE: "Water to be fetched from own house",
				TYPEOFTOILET: "Type of toilet",
				INCLUSIVETOILET: "Inclusive toilet",
				FUNCTIONALITYTOILET: "Functionality of toilet",
				UTILIZEDOFTOILET: "Utilization of Toilet Block",
				FUNCTIONLTYVSUTILIZATION: "Functionality vs Utilization of Toilet Block",
				MALEBLOCK: "Seatwise Infrastructural Deficit in male Block",
				FEMALEBLOCK: "Seatwise Infrastructural Deficit in female Block",
				CHILDBLOCK: "Seatwise Infrastructural Deficit in Child Friendly Block",
				DISABLEDBLOCK: "Seatwise Infrastructural Deficit in Disalbed Friendly Block",
				RESPONSIBILITYOFOPERATION: "Responsibility of Operation and Maintenance",
				BUILDINGCONDITION: "Building condition",
				CLIENLINESSTOILET: "Clieness of toilet block",
				CLIENLINESSALLTOILET: "Clieness of all toilet block",
				CLIENLINESSAMC: "Overall cleanliness of AMC operated toilets",
				CLIENLINESSPRIVATE: "Overall cleanliness of Privately operated toilets",
				OMAGENCY: "O&M agecy and frequency of cleanliness",
				REPAIRREGIME: "Repair Regime and frequency of Cleanliness",
				WATERDUPPLY: "Duration of water dupply in Toilet Cubicle",
				WATERTOBEFATCH: "Water to be fetched from storage tank",
			},
		},
		ECOMMERCE: {
			COMMON: {
				SELECTED_RECORDS_COUNT: "Selected records count: ",
				ALL: "All",
				SUSPENDED: "Suspended",
				ACTIVE: "Active",
				FILTER: "Filter",
				BY_STATUS: "by Status",
				BY_TYPE: "by Type",
				BUSINESS: "Business",
				INDIVIDUAL: "Individual",
				SEARCH: "Search",
				IN_ALL_FIELDS: "in all fields",
			},
			ECOMMERCE: "eCommerce",
			CUSTOMERS: {
				CUSTOMERS: "Customers",
				CUSTOMERS_LIST: "Customers list",
				NEW_CUSTOMER: "New Customer",
				DELETE_CUSTOMER_SIMPLE: {
					TITLE: "Customer Delete",
					DESCRIPTION: "Are you sure to permanently delete this customer?",
					WAIT_DESCRIPTION: "Customer is deleting...",
					MESSAGE: "Customer has been deleted",
				},
				DELETE_CUSTOMER_MULTY: {
					TITLE: "Customers Delete",
					DESCRIPTION: "Are you sure to permanently delete selected customers?",
					WAIT_DESCRIPTION: "Customers are deleting...",
					MESSAGE: "Selected customers have been deleted",
				},
				UPDATE_STATUS: {
					TITLE: "Status has been updated for selected customers",
					MESSAGE: "Selected customers status have successfully been updated",
				},
				EDIT: {
					UPDATE_MESSAGE: "Customer has been updated",
					ADD_MESSAGE: "Customer has been created",
				},
			},
		},
		//
		ACTIONS: {
			UPDATEUSERTYPE: {
				VALUE: "UPDATE_USER_TYPE",
				DISCRIPTION: "update user type",
			},
		},
	},
};
