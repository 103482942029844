import { Component, OnInit, OnDestroy, Input, Inject, HostListener, ElementRef } from "@angular/core";
import { environment } from "../../../../../../environments/environment";

@Component({
	selector: "tdata-profile",
	templateUrl: "./profile.html",
	styleUrls: ["./profile.scss"],
})
export class TDataProfile implements OnInit, OnDestroy {
	@Input() config;
	@Input() data;
	@Input() bucketImgFolder;
	showOnlineIcon = false;
	showDetails = false;
	imagebucketurl = environment.imagebucketurl;
	constructor(private elRef: ElementRef) {
		// this.config = data;
	}

	ngOnInit() {
		this.showOnlineIcon = this.config.showOnlineIcon ? this.config.showOnlineIcon : false;

		let folderName = this.config.bucketImgFolder ? this.config.bucketImgFolder : "/userpic/";
		this.imagebucketurl = environment.imagebucketurl + folderName;
	}

	handleDetailDailoge() {
		this.showDetails = !this.showDetails;
	}
	handleEdit() {
		this.config.dataConf.editAction(this.data.id);
	}
	@HostListener("document:click", ["$event"])
	onOutsideClick(event: Event): void {
		const isClickInside = this.elRef.nativeElement.contains(event.target);
		if (!isClickInside) {
			this.showDetails = false;
		}
	}
	ngOnDestroy() {}
}
