import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef, EventEmitter, Inject } from "@angular/core";
import { MatPaginator, MatSort, MatTableDataSource, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ToastrService } from "ngx-toastr";
// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";
// import * as jsPDF from "jspdf";
import * as jsPDF from "jspdf";
import html2canvas from "html2canvas";

interface singleCategory {
	_id: string;
	title: string;
	description: string;
	popular: boolean;
	status: number;
	parent: string | null;
	parent_title: string | null;
	order: number;
	subcategory: singleCategory[];
	selected: boolean | false;
	// Add other properties as needed
}

export interface DialogData2 {
	// category: Array<singleCategory>;
	// profession: Array<string>;
	// CategoryTitle: string;
	// editProfessionButton: any;
	// selectedRadioButton: any;
	chatData: any;
	clientName: any;
	managerName: any;
}
@Component({
	selector: "tchat-dialoge",
	templateUrl: "./chatLog.html",
	styleUrls: ["./chatLog.scss"],
})
export class TChatDialoge {
	private subscription;
	emailError: any = "";
	otpDisabled = false;
	selectedCatArr = [];
	inputValue = "";
	newCategoryArr: Array<singleCategory>;
	private addManager: EventEmitter<boolean> = new EventEmitter();
	SaveButtonToggle = false;
	chatData = [];
	groupedByDate = [];
	dateArr = [];
	filterChatData = [];
	constructor(public dialogRef: MatDialogRef<TChatDialoge>, private toastr: ToastrService, private cdr: ChangeDetectorRef, @Inject(MAT_DIALOG_DATA) public data: DialogData2) {}
	ngOnInit() {
		// this.data.profession
		this.chatData = this.data.chatData;
		this.addCenterDate();
	}

	export() {
		const doc = new jsPDF();
		const content = document.getElementById("pdfContent");
		if (content) {
			doc.fromHTML(content, 10, 10, {
				width: 180, // Adjust the width to fit your content
			});
			doc.save("chat-history.pdf");
		}
	}

	addCenterDate() {
		this.groupedByDate = this.chatData.reduce((acc, item) => {
			// Extract the date in UTC (YYYY-MM-DD)
			const date = new Date(item.createdAt).toISOString().split("T")[0];

			// Format it to a readable format (e.g., "23 Jan 2025")
			const formattedDate = new Date(date).toLocaleDateString("en-GB", {
				day: "numeric",
				month: "short",
				year: "numeric",
			});

			if (!acc[formattedDate]) {
				acc[formattedDate] = []; // Initialize an array for this date
			}

			acc[formattedDate].push(item); // Add the current item to the date group
			return acc;
		}, {});
		this.dateArr = Object.keys(this.groupedByDate);
		this.filterChatData = this.groupedByDate[this.dateArr[0]].filter((e) => e.content != "Requested" && e.content != "Accepted");
	}

	save(): void {}
	closeDialoge() {
		this.dialogRef.close();
	}

	formateDate(inputDate: any) {
		// const date = new Date(inputDate);

		// Format the date as "1 Jan 2025"
		// const formattedDate = date.toLocaleString("en-US", {
		// 	year: "numeric",
		// 	month: "short",
		// 	day: "numeric",
		// });

		// // Format the time as "3:41PM"
		// const formattedTime = date.toLocaleString("en-US", {
		// 	hour: "numeric",
		// 	minute: "numeric",
		// 	hour12: true,
		// });
		const date = new Date(inputDate);

		const formattedDate = `${date.getUTCDate()} ${date.toLocaleString("en-US", { month: "short", timeZone: "UTC" })} ${date.getUTCFullYear()}`;
		const hours = date.getUTCHours();
		const minutes = date.getUTCMinutes();
		const formattedTime = `${hours % 12 || 12}:${minutes.toString().padStart(2, "0")}${hours >= 12 ? "PM" : "AM"}`;

		// const result = `${formattedDate} - ${formattedTime}`;
		return { formattedDate, formattedTime };
	}
	// ngOnDestroy(): void {
	// 	this.newCategoryArr = [];
	// 	this.dialogRef.close(this.selectedCatArr);
	// }
}
