// Angular
import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef } from "@angular/core";
// RxJS
import { Observable, BehaviorSubject, Subscription, of } from "rxjs";

import { AuthService } from "../../../../../core/modules/auth/service/auth.service";
import { Router, ActivatedRoute } from "@angular/router";
import { User } from "./model/user.model";
import * as jwt_decode from "jwt-decode";
import { environment } from "../../../../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { catchError, map, startWith, switchMap } from "rxjs/operators";
import { SharedService } from "../../../../../core/common/services/shared.service";
import { UserProfileService } from "../../../../../core/common/services/user-profile-auth.service";

import Bugsnag from "@bugsnag/js";
@Component({
	selector: "kt-user-profile",
	templateUrl: "./user-profile.component.html",
	styleUrls: ["./user-profile.component.scss"],
})
export class UserProfileComponent implements OnInit, OnDestroy {
	// Public properties
	user = new User();
	subscription: Subscription;
	imageBucketUrl: any = environment.imagebucketurl + "/profile_images/";
	@Input() showAvatar: boolean = true;
	@Input() showHi: boolean = true;
	@Input() showBadge: boolean = false;
	@Input() showMenu: boolean = true;
	showCount: boolean = true;
	notificationCount: string = null;
	token: any;
	tokenData: any;
	routeData: any;
	permissionArr: any;
	countToggle = true;

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 */
	constructor(private activatedRoute: ActivatedRoute, private router: Router, private auth: AuthService, private myRoute: Router, private http: HttpClient, private sharedService: SharedService, private userProfileService: UserProfileService, private cdr: ChangeDetectorRef) {}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	async ngOnInit() {
		try {
			this.countToggle = false;
			this.subscription = this.sharedService.updateComponent$.subscribe(() => {
				this.getNotificationCount();
			});
			this.token = localStorage.getItem("authToken");
			this.tokenData = jwt_decode(this.token);
			this.permissionArr = this.tokenData.usergroup[0].permission || [];

			this.subscription = this.auth.currentUser$.subscribe((currentUser) => {
				this.user = currentUser;
			});
			this.getNotificationCount();
			this.showCount = false;
			this.userProfileService.updateComponent$.subscribe((val) => {
				this.showCount = val;
			});
		} catch (error) {
			Bugsnag.notify(error);
		}
	}

	opennotifications() {
		this.router.navigate(["/admin/consolenotification"]);
		// this.myRoute.navigateByUrl("admin/consolenotification");
		// this.myRoute.navigateByUrl("admin/consolenotification");
	}
	getNotificationCount() {
		try {
			this.http
				.post(`${environment.baseURL}consolenotification/getNotificationCount`, {})
				.pipe(
					map((response: any) => {
						let data = response.data; // Assuming the count is returned in the response
						if (data > 0) {
							this.notificationCount = data;
						}
					}),
					catchError((err) => {
						console.error(err);
						return of(null); // Use 'of' to return an observable
					})
				)
				.subscribe();
		} catch (error) {
			console.error(error);
		}
	}
	ngOnDestroy() {
		// tslint:disable-next-line: no-unused-expression
		this.subscription && this.subscription.unsubscribe();
	}
	/**
	 * Log out
	 */
	logout() {
		// this.store.dispatch(new Logout());
	}
}
